import React, { Component } from "react";
import { Layout, PortfolioNav, PortfolioHeader } from "../../../components";
import locales from "../../../constants";

class UiP extends Component {
  render() {
    const nav = {
      initialSlideIndex: 1,
    };
    const lang = "pl";
    const { slug } = this.props.pageContext;
    return (
      <Layout
        header={{
          background:
            "linear-gradient( 45deg, #9B394A, #AE3F53, #D24E65, #AE3F53, #9B394A )",
          icons: "#D24E65",
          navClass: "uip",
        }}
        seo={{
          title: "Uwodzenie i podryw",
          headerTitle: "uip",
          href: slug,
          lang: "en",
          ogImage: require("../../../assets/img/portfolio/uip_top_back.jpg"),
        }}
        langSwitch={{
          langKey: "pl",
          langSlug: "/projekty/uwodzenie-i-podryw/",
        }}
      >
        <PortfolioHeader name="uip" height="252" />
        <section className="container-fluid uip_section_2" id="info">
          <div className="row">
            <div className="col-md-6">
              <div className="inner">
                <h1>Uwodzenie i podryw</h1>
                <ul>
                  <li>Website</li>
                  <li>Banner ads</li>
                  <li>Lead campaign</li>
                </ul>
              </div>
            </div>
            <div className="col-md-6">
              <div className="inner">
                <p>
                Rafał Żuber approached us based on a recommendation from another Client who worked with us in the past. Rafał, who is a well known expert on male-female relations, wanted us to create a set of business tools that would allow for him to become the leader of his industry both when it comes to brand and financial results.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="container-fluid uip_section_3">
          <div className="row">
            <div className="col-md-5">
              <img
                src={require("../../../assets/img/portfolio/Audyt_UiP.jpg")}
                alt=""
                className="img-fluid mockup"
              />
            </div>
            <div className="col-md-5 offset-md-1 col">
              <div className="inner">
                <h2>
                Learn by mistakes... <br></br>
                  .....best if not yours
                </h2>
                <p>
                For the purposes of the project, we conducted an industry audit and presented the results of our work in a report of several dozen pages. This report was also the foundation for further work on the Rafał's business model and website mockups.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="container-fluid uip_section_4">
          <div className="row no-gutters">
            <div className="col-md-5 offset-md-1 text">
              <div className="inner">
                <h2>Client Consultations</h2>
                <p>
                As a part of our cooperation, we trained Rafał in planning and drawing business processes, and implementing marketing automation solutions in order to convert the users visiting his website and social media channels into regular customers.
                </p>
              </div>
            </div>
            <div className="col-md-5 offset-md-1 image_holder">
              <img
                src={require("../../../assets/img/portfolio/Procesess_UiP.png")}
                alt=""
                className="img-fluid"
              />
            </div>
          </div>
        </section>

        <section className="container-fluid uip_section_5">
          <div className="row row_1">
            <div className="col-md-5">
              <img
                src={require("../../../assets/img/portfolio/Makieta_UiP.png")}
                alt=""
                className="img-fluid"
              />
            </div>
            <div className="col-md-5 offset-md-1 col_1">
              <div className="inner">
                <h2>Mockups</h2>
                <p>
                The final shape and mechanics of the portal have been developed thanks to very advanced prototyping. The scheme of how to implement the website had been defined very precisely, so we spent a lot of time on planning the flow of users and the way to achieve their goals that were set in the business assumptions. The key aspect of the implementation was sales, so we took care of appropriate cross / up selling mechanisms.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="container-fluid uip_section_6">
          <div className="row">
            <div className="col-md-5 offset-md-1 col_2">
              <div className="inner">
                <h2>Website</h2>
                <p>
                Thanks to our rebranding Uwodzenie i Podryw became a well recognized brand, that turned into the leader of the industry in Poland. The new website fulfilled the whole process of rebranding, and assured a significant improvement in sales indicators, thanks to the advanced service of business mechanisms and sales support - one more  proof that UX and design does really matter :)
                </p>
              </div>
            </div>
            <div className="col-md-6 text-right">
              <div className="scroll_wrapper">
                <span />
                <div className="img_wrapper">
                  <img
                    src={require("../../../assets/img/portfolio/UiP_page.jpg")}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="container-fluid uip_section_7">
          <div className="row">
            <div className="col-md-5 img-holder">
              <img
                className="img-fluid image1"
                src={require("../../../assets/img/portfolio/Site1_UiP.png")}
                alt=""
              />
            </div>
            <div className="col-md-5 offset-md-1 col_3">
              <div className="inner">
                <p>
                We also took care of the image update of older marketing materials. We adapted them to match the new version of the UiP brand and prepared a strategy for further image-building activities.
                </p>
              </div>
            </div>
          </div>
        </section>

        <div className="container-fluid uip_section_8">
          <div className="row">
            <div className="col-md-5 offset-md-1">
              <img
                className="img-fluid image2"
                src={require("../../../assets/img/portfolio/Site2_UiP.png")}
                alt=""
              />
            </div>
            <div className="col-md-5 offset-md-1">
              <img
                src={require("../../../assets/img/portfolio/Kampania_UiP.png")}
                alt=""
                className="img-fluid image3"
              />
            </div>
          </div>
        </div>

        <section className="container-fluid uip_section_9">
          <div className="row">
            <div className="col-md-5">
              <img
                src={require("../../../assets/img/portfolio/Youtube_UiP.png")}
                alt=""
                className="img-fluid yt"
              />
            </div>
            <div className="col-md-5 offset-md-1 text_holder">
              <div className="inner">
                <h2>Lead Campaign</h2>
                <p>
                We conducted a sales campaign, also preparing materials such as banners or landing pages. Rafał has also been trained in the field of self-promotion of products and himself (as an inseparable part of the UiP brand) in social media, as well as taking these elements into account in the processes of planning his business and implementing subsequent product strategies.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="container-fluid uip_section_10">
          <div className="container">
            <h2>Recommendation</h2>
            <div className="content">
              <img
                src={require("../../../assets/img/portfolio/portret_UiP.png")}
                alt=""
                className="img-fluid"
              />

              <p>
              My first website was created in 2008. Since then, I have worked with many companies and often hada complaints on the quality of received services. When I first contacted Łukasz Pach by phone, I knew at once that we would establish cooperation. In the telephone conversation, it was clear that ADream focuses on helping people, and not primarily on their earnings. This convinced me to work with them.
              </p>
            </div>
            <div className="first-btn">
              <button
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  window.location.href =
                    "https://adream.pl/referencje/joy-for-the-people-rafal-zuber";
                }}
              >
                {" "}
                Show more
              </button>
            </div>
          </div>
        </section>
        {/* <section className="container-fluid uip_section_7">
            <div className="row">
            <div className="col-md-6 text-right">


            </div>
         <div className="col-md-5 offset-md-1">
         <img
                src={require("../../assets/img/portfolio/Kampania_UiP.png")}
                alt=""
                />
                </div>
             </div>
        </section> */}

        <PortfolioNav data={nav} locales={locales[lang]} />
      </Layout>
    );
  }
}

export default UiP;
